export default {
    namespaced: true,
    state: {
        mainMenuList: [
            {'className': 'btn-15', "path": '/expand/chat', 'name': 'ChatGPT', 'role': 'ordinary'},
            {'className': 'btn-3', "path": '/expand/externalApp', 'name': '创意应用', 'role': 'ordinary'},
            {'className': 'btn-2', "path": '/mailbox/list', 'name': "时光邮差", 'role': 'ordinary'},
            {'className': 'btn-11', "path": '/expand/christmasTree/morningReport', 'name': "时光日记", 'role': 'ordinary'},
            {'className': 'btn-14', "path": '/expand/christmasTree/souvenir', 'name': "时光相册", 'role': 'ordinary'},
            {'className': 'btn-13', "path": '/expand/fileUpload', 'name': "文件快传", 'role': 'ordinary'},
            {'className': 'btn-6', "path": '/player', 'name': "视频播放器", 'role': 'ordinary'},
            {'className': 'btn-4', "path": '/tvRoom', 'name': '一起看', 'role': 'ordinary'},
            {'className': 'btn-4', "path": '/expand/nas', 'name': 'Nas', 'role': 'admin'},
            {'className': 'btn-1', "path": '/treeHole/group', 'name': "树洞先生", 'role': 'ordinary'},
            {'className': 'btn-5', "path": '/tv', 'name': '电视频道', 'role': 'ordinary'},
            {'className': 'btn-7', "path": '/expand/mediaLibrary', 'name': '图库', 'role': 'ordinary'},
            {'className': 'btn-1', "path": '/expand/videoPlay', 'name': '播放器', 'role': 'ordinary'},
            {'className': 'btn-15', "path": '/expand/filev/index', 'name': '离线下载', 'role': 'admin'},
            {'className': 'btn-10', "path": 'https://www.vkna.cn/', 'name': 'Fc小游戏', 'role': 'ordinary'},
            {'className': 'btn-8', "path": '/pv', 'name': 'Pv', 'role': 'pv'},
            {'className': 'btn-15', "path": '/expand/novel/index', 'name': '小说', 'role': 'pv'},
            {'className': 'btn-1', "path": '/expand/weChatTemplate', 'name': '公众号消息', 'role': 'admin'}
        ]
    },
    getters: {
        getMainMenuList: state => state.mainMenuList
    },
    actions: {
        //
    },
    mutations: {
        pushMainMenuList: (state, data) => state.mainMenuList.push(data)
    }
}
