const zh = {
    App: '大橙同学',
    title_: '大橙同学-',
    test: '你好',
    language: {
        zh: '中文',
        en: 'English',
    },
    login: {
        accountHint: '请输入账号(用户名/邮箱/手机)',
        passwordHint: '请输入密码',
        loginButton: '登录',
        register: '注册',
        reset:  '重置',
        save:  '保存',
        rememberPassword: '记住密码',
        retrievePassword: '忘记密码'
    },
    // 菜单
    menus: {
        app: {
            userInfo: '用户信息',
            playRecord: '播放记录',
            charonHollow: '树洞树洞',
            timePostman: '时光邮差',
            m3u8Play: 'M3U8',
            backstage: '后台管理',
            points: '橙点钱包',
        }
    }
}
export default zh;
