import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const title = '大橙同学'
const routes = [
    {
        path: '/',
        name: 'App',
        component: () => import('../views/index/index.vue'),
        meta: {
            title: title,
            keepAlive: true
        }

    },
    {
        path: '/points',
        name: 'points',
        component: () => import('../views/users/points.vue'),
        meta: {
            title: title + '-橙点钱包',
            keepAlive: false
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/users/login.vue'),
        meta: {
            title: title + '-登录页',
            keepAlive: false
        }
    },
    {
        path: '/wechatSso',
        name: 'wechatSso',
        component: () => import('../views/users/wechatSso.vue')
    },
    {
        path: '/player',
        name: 'm3u8Play',
        component: () => import('../views/expand/m3u8/Artplayer.vue'),
        meta: {
            title: title + '-媒体播放器',
            keepAlive: false
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/users/register.vue'),
        meta: {
            title: title + '-注册'
        }
    },
    {
        path: '/userInfo',
        name: 'userInfo',
        component: () => import('../views/users/userInfo.vue'),
        meta: {
            title: title + '-用户信息'
        }
    },
    {
        path: '/tv',
        name: 'vodTv',
        component: () => import('../views/expand/tv/index.vue'),
        meta: {
            title: title + '-电视频道'
        }
    },
    {
        path: '/videoPlay',
        name: 'videoPlay',
        component: () => import('../views/index/videoPlay.vue'),
        meta: {
            title: title
        }
    },
    {
        path: '/camera',
        name: 'TakePhotos',
        component: () => import('../views/expand/camera/index.vue')
    },
    {
        path: '/playRecord',
        name: 'playRecord',
        component: () => import('../views/index/playRecord.vue'),
        meta: {
            title: title + '-播放记录'
        }
    },
    {
        path: '/tvRoom',
        name: 'tvRoom',
        component: () => import('../views/expand/tvRoom/index.vue'),
        meta: {
            title: title + '-一起看'
        }
    },
    {
        path: '/pv',
        name: 'pvMiddle',
        component: () => import('../views/expand/pv/middle.vue'),
        meta: {
            title: title + '-PV频道'
        },
    },
    {
        path: '/admin',
        name: 'vieww',
        component: () => import('../views/index/vieww.vue'),
        children: [
            {
                path: 'index',
                name: 'admin_index',
                component: () => import('../views/admin/index.vue'),
                meta: {title: title + '-后台管理'},
            },
            {
                path: 'externalApp',
                name: 'admin_externalApp',
                component: () => import('../views/admin/externalApp.vue'),
                meta: {title: title + '-后台管理'},
            },
            {
                path: 'insideTv',
                name: 'insideTvAdd',
                component: () => import('../views/admin/insideTvAdd.vue'),
                meta: {title: title + '-内置影视'},
            },
            {
                path: 'gpt/message',
                name: 'chatGPTMsg',
                component: () => import('../views/admin/chatGPT/chatGPTMsg'),
                meta: {title: title + '-chatGPT后台消息'}
            },
            {
                path: 'system/set',
                name: 'SystemSet',
                component: () => import('../views/admin/SystemSet'),
                meta: {title: title + '-系统设置'}
            },
            {
                path: 'videoSearch/set',
                name: 'videoSearchSet',
                component: () => import('../views/admin/VideoSearchSet'),
                meta: {title: title + '-资源搜索配置'}
            },
            {
                path: 'banner',
                name: 'banner',
                component: () => import('../views/admin/banner'),
                meta: {title: title + '-banner管理'}
            },
            {
                path: 'macCms',
                name: 'macCms',
                component: () => import('../views/admin/macCms'),
                meta: {title: title + '-macCms管理'}
            }
        ],
    },
    {
        path: '/treeHole',
        name: 'treeHole',
        component: () => import('../views/index/vieww.vue'),
        children: [
            {
                path: 'group',
                name: 'treeHoleGroup',
                component: () => import('../views/hole/group.vue'),
                meta: {title: title + '-树洞列表'},
            },
            {
                path: 'msg',
                name: 'treeHoleMsg',
                component: () => import('../views/hole/index.vue'),
                meta: {title: title + '-树洞'}
            }
        ],
    },
    {
        path: '/mailbox',
        name: 'mailbox',
        component: () => import('../views/index/vieww.vue'),
        children: [
            {
                path: 'list',
                name: 'mailbox_list',
                component: () => import('../views/expand/mailbox/list.vue'),
                meta: {title: title + '-时光邮差'}
            },
            {
                path: 'edit',
                name: 'mailbox_edit',
                component: () => import('../views/expand/mailbox/edit.vue'),
                meta: {title: title + '-时光邮差'}
            },
        ],
    },
    {
        path: '/expand',
        name: 'expand',
        component: () => import('../views/index/vieww.vue'),
        children: [
            {
                path: 'weChatTemplate',
                name: 'weChatTemplate',
                component: () => import('../views/expand/weChatTemplate.vue')
            },
            {
                path: 'novel',
                name: 'novel',
                component: () => import('../views/index/vieww.vue'),
                meta: {title: title + '-大橙小说'},
                children: [
                    {
                        path: 'index',
                        name: 'novel_index',
                        component: () => import('../views/expand/novel/index.vue'),
                        meta: {title: title + '-大橙小说'}
                    },
                    {
                        path: 'details',
                        name: 'navel_see',
                        component: () => import('../views/expand/novel/details.vue'),
                        meta: {title: title + '-大橙小说'}
                    }]
            },
            {
                path: 'nas',
                name: 'nas',
                component: () => import('../views/expand/nas.vue')
            },
            {
                path: 'externalApp',
                name: 'external_app',
                component: () => import('../views/expand/ExternalApp.vue')
            },
            {
                path: 'filev',
                name: 'filev',
                meta: {title: title + '-离线下载'},
                component: () => import('../views/index/vieww.vue'),
                children: [
                    {
                        path: 'index',
                        name: 'file_index',
                        component: () => import('../views/expand/downFile/index.vue'),
                        meta: {title: title + '-离线下载'}
                    },
                    {
                        path: 'fileDown',
                        name: 'fileDown',
                        component: () => import('../views/expand/downFile/fileDown.vue'),
                        meta: {title: title + '-文件下载'}
                    }
                ],
            },
            {
                path: 'fileUpload',
                name: 'fileUpload',
                component: () => import('../views/expand/fileUpload.vue'),
                meta: {title: title + '-文件快传'}
            },
            {
                path: 'fkcShow',
                name: 'fkcShow',
                component: () => import('../views/expand/FileKcShow.vue'),
                meta: {title: title + '-文件快传'}
            },
            {
                path: 'videoPlay',
                name: 'videoPlayers',
                component: () => import('../views/expand/video_play/VideoPlayers.vue')
            },
            {
                path: 'mediaLibrary',
                name: 'media_Library',
                component: () => import('../views/expand/charonLibrary/mediaLibrary'),
                meta: {title: title + '-图库'}
            },
            {
                path: 'christmasTree',
                name: 'christmasTree',
                component: () => import('../views/index/vieww.vue'),
                children: [
                    {
                        path: 'morningReport',
                        name: 'morningReport',
                        component: () => import('../views/expand/ChristmasTree/morningReport.vue'),
                        meta: {title: title + '-时光日记'}
                    },
                    {
                        path: 'external',
                        name: 'external',
                        component: () => import('../views/expand/ChristmasTree/index.vue'),
                        meta: {title: title + '-应用'}
                    },
                    {
                        path: 'souvenir',
                        name: 'souvenir',
                        component: () => import('../views/expand/photoAlbum/souvenir.vue'),
                        meta: {title: title + '-时光相册'}
                    },
                    {
                        path: 'souvenir/segment',
                        name: 'souvenir_segment',
                        component: () => import('../views/expand/photoAlbum/segment.vue'),
                        meta: {title: title + '-时光相册'}
                    }
                ]
            },
            {
                path: 'onLineCinema',
                name: 'onLineCinema',
                component: () => import('../views/expand/tvRoom/middle.vue'),
                meta: {title: title + '-一起看'},
            },
            {
                path: 'chat',
                name: 'GptChat',
                component: () => import('../views/expand/GptChat'),
                meta: {title: title + '-大橙AI'}
            },
            {
                path: 'weChat',
                name: 'weChat',
                component: () => import('../views/index/vieww.vue'),
                children: [
                    {
                        path: 'subscribe',
                        name: 'weChatSubscribe',
                        component: () => import('../views/expand/weChat/subscribe.vue')
                    },
                    {
                        path: 'jump',
                        name: 'weChatJump',
                        component: () => import('../views/expand/weChat/jump.vue')
                    }
                ]
            }
        ],
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/index/vieww.vue'),
        children: [
            {path: 'test', name: 'test_test', component: () => import('../views/test/test.vue')},
            {path: 'index', name: 'test_Index', component: () => import('../views/test/index.vue')},
            {path: 'index2', name: 'test_Index2', component: () => import('../views/test/index2.vue')},
            {path: 'index3', name: 'test_Index3', component: () => import('../views/test/index3.vue')},
            {path: 'index4', name: 'test_Index4', component: () => import('../views/test/index4.vue')},
            {path: 'index5', name: 'test_Index5', component: () => import('../views/test/index5.vue')},
            {path: 'index6', name: 'test_Index6', component: () => import('../views/test/index6.vue')},
            {path: 'index7', name: 'test_Index7', component: () => import('../views/test/index7.vue')},
            {path: 'index8', name: 'test_Index8', component: () => import('../views/test/index8.vue')},
            {path: 'index9', name: 'test_Index9', component: () => import('../views/test/index9.vue')},
        ],
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
