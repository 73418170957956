export default {
    namespaced: true,
    state: {
        // 公共配置
        common: {
            sentCodeMode: 0,
            videoPlayerAdd: "",
            gGShow: false
        },
        // 视频搜索配置
        tvResSearch: {}
    },
    getters: {
        getCommon: state => state.common,
        getGGShow: state => state.common.gGShow,
        getTvResSearch: state => state.tvResSearch,
    },
    actions: {
        //
    },
    mutations: {
        setCommon: (state, data) => {
            state.common = data
        },
        setTvResSearch: (state, data) => {
            state.tvResSearch = data
        },
        closeGgShow: (state, data) => {
            state.common.gGShow = false
        },
    }
}
