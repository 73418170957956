import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class users {
    constructor() {
        this.http = new HttpHandler();
    }

    //登录
    login(data) {
        let url = '/api/user/loginUser';
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }

    //发送验证码
    sendEmailCode(data) {
        let url = '/api/user/sendEmailCode';
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }

    //登录
    regUser(data) {
        let url = '/api/user/regUser';
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }

    //修改
    updUser(data) {
        let url = '/api/user/updUser';
        return this.http.put(url, data, HttpConstant.FORM_DATA_REQ);
    }

    // 修改密码
    updPwd(data) {
        let url = '/api/user/retrievePwd';
        return this.http.put(url, data, HttpConstant.FORM_DATA_REQ);
    }

    wechatSso(data) {
        return this.http.post('/api/user/wechatSso', data, HttpConstant.FORM_DATA_REQ);
    }

    getWxOpenId(data) {
        return this.http.post('/api/user/getWxOpenId', data, HttpConstant.FORM_DATA_REQ);
    }

    // 判断是否登录
    loginJudge(data) {
        return this.http.get('/api/user/loginJudge', data, HttpConstant.FORM_DATA_REQ);
    }

    // 退出登录
    userOut() {
        return this.http.post('/api/user/userOut', null, HttpConstant.FORM_DATA_REQ);
    }
}
