<template>
  <div id="app">
    <div id="head">
      <el-row type="flex" class="appHand" justify="space-between">
        <el-col :span="2.5">
          <i :class="[isHideBack ? 'el-icon-back' : 'el-icon-menu']" class="el-icon-back"
             style="cursor: pointer; font-size: 30px; padding-top: 7px; text-align: left"
             @click="rollback"/>
        </el-col>
        <el-col :span="19"
                style="font-size: 18px; font-weight: bold; padding-top: 7px; text-align: center">
          <span style="margin-left: 10px; cursor:pointer;" @click="$router.push({path: '/'})">{{ $t('App') }}</span>
        </el-col>
        <el-col :span="2.5">
          <el-dropdown trigger="click" @command="jumpView">
          <span style="width: 30px; height: 30px">
            <el-avatar class="el-dropdown-link"
                       :src="$store.state.user.userInfo.lUsrIcon === '' ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : '/api/resources/user/' + $store.state.user.userInfo.lUsrId + '/' + $store.state.user.userInfo.lUsrIcon">
              <el-avatar src="/charon.png"/>
            </el-avatar>
          </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="3" v-if="!$store.state.user.isLogin">用户登录</el-dropdown-item>
              <el-dropdown-item command="1">{{ $t('menus.app.userInfo') }}</el-dropdown-item>
              <el-dropdown-item command="6">{{ $t('menus.app.points') }}</el-dropdown-item>
              <el-dropdown-item command="5"
                                v-if="$store.state.user.userInfo.luAdmin">
                {{ $t('menus.app.backstage') }}
              </el-dropdown-item>
              <el-dropdown-item command="2">{{ $t('menus.app.playRecord') }}</el-dropdown-item>
              <el-dropdown-item command="4" v-if="$store.state.user.isLogin">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
    <router-view/>
    <div>
      <el-drawer style="text-align: center"
                 title="菜单"
                 :visible.sync="drawer"
                 direction="ltr"
                 :show-close="false"
                 size="50%"
                 custom-class="charon_drawer"
                 :before-close="handleClose">
        <section>
          <div>
            <ul class="ul1"
                style="height:95vh;overflow-y:scroll;list-style: none;padding-left: 0px; margin: 0px; text-align: center">
              <li class="li1" v-for="(item, index) in $store.state.common.mainMenuList" :key="index"
                  @click="drawer = false">
                <mainMenu :className="item.className" :name="item.name" :path="item.path"
                          v-if="roleVerifyMenu($store.getters['user/getUserRole'], item.role)"/>
              </li>
            </ul>
          </div>
        </section>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import usersAsk from "./request/users";
import mainMenu from '@/components/MainMenu'
import SystemConfig from "./request/SystemConfig";

const {roleVerifyMenu} = require("@/utils/TvUtil");
export default {
  name: 'App',
  components: {mainMenu},
  data() {
    return {
      drawer: false,
      usersAsk: new usersAsk(),
      isHideBack: false,
      userInfo: null,
    }
  },
  methods: {
    roleVerifyMenu(i, j) {
      return roleVerifyMenu(i, j);
    },
    async getSystemComConfig() {
      new SystemConfig().getSystemComConfig().then(res => {
        this.$store.commit("systemConfig/setCommon", res)
      })
    },
    handleClose(done) {
      done();
    },
    jumpView(index) {
      switch (index) {
        case "1":
          this.$router.push({path: '/userInfo'})
          break;
        case "2":
          this.$router.push({path: '/playRecord'})
          break;
        case "3":
          this.$router.push({path: '/login'})
          break;
        case "4":
          this.usersAsk.userOut().then(res => {
            if (res.code === 'success') {
              this.$storageHandler.removeSessionStorage('loginUser');
              this.changeUserInfo();
              this.$message({
                showClose: true,
                message: '退出登录成功',
                type: 'success'
              });
            }
          })
          break;
        case "5":
          this.$router.push({path: '/admin/index'})
          break;
        case "6":
          this.$router.push({path: '/points'})
          break;
        default:
          break
      }
    },
    rollback() {
      if (this.isHideBack) {
        this.$router.go(-1)
      } else {
        this.drawer = true
      }
    },
    /**
     * 监听用户信息变化事件
     */
    changeUserInfo() {
      let data = sessionStorage.getItem('loginUser');
      const userInfo = JSON.parse(data);
      if (data != null && userInfo != null) {
        this.userInfo = userInfo;
        this.$store.commit("user/setIsLogin", true)
        this.$store.commit("user/setUserInfo", userInfo)
      } else {
        this.$store.commit("user/setIsLogin", false)
        this.$store.commit("user/setUserInfo", {
          "lUsrEmail": "",
          "lUsrIcon": "",
          "lUsrId": new Date().getTime(),
          "lUsrName": "游客",
          "lUsrOpenId": "",
          "lUsrPhone": "",
          "lUsrPwd": "",
          "lUsrQQ": "",
          "lUsrWechat": "",
          "luAdmin": false,
          "lUsrRole": "ordinary"
        })
      }
    }
  },
  async created() {
    this.getSystemComConfig();
    // let res = await this.usersAsk.loginJudge({"time": new Date().getTime()});
    // if (res.code === 'success') {
    //   let userStr = JSON.stringify(res.msg)
    //   sessionStorage.setItem("loginUser", userStr);
    //   this.$store.commit("user/setIsLogin", true)
    //   this.resetSetItem("loginUser", userStr);
    // } else {
    //   sessionStorage.removeItem('loginUser');
    //   this.changeUserInfo();
    // }
    window.addEventListener("setItem", this.changeUserInfo());
  },
  watch: {
    '$route.path': function (o, n) {
      switch (o) {
        case "/":
        case "/login":
          this.isHideBack = false
          break
        default:
          this.isHideBack = true
          break
      }
      switch (n) {
        case "/login":
          this.changeUserInfo()
          break
        default:
          break
      }
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

#head {
  height: 40px
}

.appHand > .el-col {
  vertical-align: middle;
}

.charon_drawer > .el-drawer__header {
  margin: 0;
  padding: 0;
}

.el-drawer__header {
  font-size: 16px;
  font-weight: bold;
}
</style>