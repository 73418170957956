import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import store from './store' // 引入store
import lemonMessageMarkdown from './components/lemonMessage/markdown';

Vue.component("lemon-message-markdown", lemonMessageMarkdown);
import i18n from './i18n/'
import storageHandler from './utils/StorageHandler'

import less from 'less'
import LemonIMUI from 'lemon-imui';
import 'lemon-imui/dist/index.css';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './assets/css/global.css'
import VueRouter from 'vue-router'
import UsersAsk from "@/request/users";

Vue.use(LemonIMUI);
Vue.use(less)
Vue.use(VueQuillEditor)
// Vue.config.ignoredElements = ["wx-open-subscribe"];

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.prototype.$storageHandler = storageHandler
Vue.use(ElementUI)
Vue.config.productionTip = false

let usersAsk = new UsersAsk();

//标签页设置title
router.beforeEach((to, from, next) => {
    to.meta.title && (document.title = to.meta.title);
    usersAsk.loginJudge({"time": new Date().getTime()}).then(res => {
        if (res.code === 'success') {
            let userStr = JSON.stringify(res.msg)
            sessionStorage.setItem("loginUser", userStr);
            store.commit("user/setIsLogin", true)
        } else {
            sessionStorage.removeItem('loginUser');
        }
        next()
    }).catch(e => {
        next()
    })
});

/**
 * 监听缓存
 * @param key
 * @param newVal
 */
Vue.prototype.resetSetItem = function (key, newVal) {
    if (key === 'loginUser') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
}


let vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

export default vue