import Vue from 'vue'
import Vuex from 'vuex'

import user from './module/user'
import common from './module/Common'
import systemConfig from './module/SystemConfig'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        //
    },
    mutations: {
        //
    },
    actions: {
        //
    },
    modules: {
        user,
        common,
        systemConfig
    }
})
export default store;