<script>
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

export default {
  components: {
    MarkdownItVue,
  },
  name: "lemonMessageMarkdown",
  inheritAttrs: false,
  inject: ["IMUI"],

  render() {
    return (
        <lemon-message-basic
            class="lemonMessageMarkdown"
            props={{...this.$attrs}}
            scopedSlots={{
              content: props => {
                let content = this.IMUI.emojiNameToImage(props.content);
                return <markdown-it-vue className="md-body"
                                        content={content}/>
              }
            }}
        />
    );
  }
};
</script>
<style scoped lang="less">
.md-body {
  /*width: 40%;*/
}
/deep/.markdown-body pre{
  //width: 220px !important;;
  overflow:auto;
}
/deep/.markdown-body{
  font-size: 14px !important;
}
@media (max-width: 750px) {
  /deep/ pre {
    //all: initial;
    width: 220px !important;;
    /*display: inline-block; !* 将code标签设置为内联块元素 *!*/
    /*max-width: 100%; !* 设置最大宽度为父级宽度 *!*/
  }

  ///deep/ pre {
  //  padding: 14px;
  //  overflow: auto;
  //  //font-size: 85%;
  //  line-height: 1.45;
  //  background-color: #f6f8fa;
  //  border-radius: 3px;
  //  word-wrap: normal;
  //}
  ///deep/ code {
  //  display: inline;
  //  padding: 0;
  //  margin: 0;
  //  overflow: visible;
  //  line-height: inherit;
  //  word-wrap: normal;
  //  background-color: initial;
  //  border: 0;
  //  font-size: 100%;
  //  word-break: normal;
  //  white-space: pre;
  //  background: transparent;
  //}
}
</style>