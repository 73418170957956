module.exports = {
  title: "大橙同学", // 项目名称
  homePage: {
    name: "首页",
    path: "/wel/index"
  },
  auth: false, // 是否校验登录
  sign: false, // 是否开启签名
  sso: true, // 是否开启单点登录
  storate: {
    expireTime: 1000 * 60 * 60 * 3,
    sessionStorageKey: "SESSION",
    localStorageKey: "LOCAL"
  },
  weChatSso: {
    APPID: 'wx99221ec69dc4ded3', // APPID
    LOCALLINK: 'https://charonv.com/wechatSso'
  },
  ras: {
    open: true,
  },
  defaultColor:{
    mainColor:'#1890ff',
    secondColor:'#6BC3E1',
  },
}
