export default {
    namespaced: true,
    state: {
        isLogin: false,
        openId: "",
        userInfo: {
            "lUsrEmail": "",
            "lUsrIcon": "",
            "lUsrId": 0,
            "lUsrName": "游客",
            "lUsrOpenId": "",
            "lUsrPhone": "",
            "lUsrPwd": "",
            "lUsrQQ": "",
            "lUsrWechat": "",
            "luAdmin": false,
            "lUsrRole": "ordinary"
        }
    },
    getters: {
        getIsLogin: state => state.isLogin,
        getUserInfo: state => state.userInfo,
        getUserRole : state => state.userInfo == null ? null : state.userInfo.lUsrRole
    },
    actions: {
        //
    },
    mutations: {
        setIsLogin: (state, data) => {
            state.isLogin = data
        },
        setUserInfo: (state, data) => {
            state.userInfo = data
        },
        setOpenId: (state, data) => {
            state.openId = data
        }
    }
}
