import CryptoJS from 'crypto-js';


const data = "charon2021021900";
const srcs = CryptoJS.enc.Utf8.parse(data);
const key = CryptoJS.enc.Utf8.parse('charon2021021900');//Latin1 w8m31+Yy/Nw6thPsMpO5fg==

export default class cryptoUtil {
    //加密
    encryptFun(word) {
        let srcs = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
        return encrypted.toString();
    }

    //解密
    decryptFun(word) {
        let decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }

}


