import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";
import this_ from '@/main'

export default class SystemConfig {
    constructor() {
        this.http = new HttpHandler();
    }

    /**
     * 获取公共配置
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSystemComConfig() {
        let url = '/api/system/getComConfig';
        return this.http.post(url, null, HttpConstant.FORM_DATA_REQ);
    }

    /**
     * 修改公共配置
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    setComConfig(data) {
        let url = '/api/system/setComConfig';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 记录操作日志
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    recordOperation(data) {
        let url = '/api/system/recordOperation';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }
}

/**
 * 记录操作日志
 * @param oprValue 操作记录值
 * @param oprDesc 描述
 * @returns {Promise<AxiosResponse<*>>}
 */
export function recordSysOpera(oprValue, oprDesc) {
    let data = {
        "oprUsrId": this_.$store.state.user.userInfo == null ? "" : this_.$store.state.user.userInfo.lUsrId,
        "oprType": "客户端记录",
        "oprValue": oprValue,
        "oprDesc": oprDesc
    }
    let ask = new SystemConfig();
    return ask.recordOperation(data);
}
