const en = {
    App: '李棋同学',
    test: 'hello',
    language: {
        zh: '中文',
        en: 'English',
    },
    login: {
        accountHint: 'Please enter your account number',
        passwordHint: 'Please enter your password',
        loginButton: 'LogIn',
        register: 'Register',
        reset:  'Reset',
        save:  'Save',
        rememberPassword: 'RememberPassword',
        retrievePassword: '忘记密码'
    },
    // 菜单
    menus: {
        app: {
            userInfo: 'UserInfo',
            playRecord: 'PlayRecord',
            charonHollow: 'TreeHole',
            timePostman: 'timePostman',
            m3u8Play: 'M3U8',
            backstage: 'backstage',
            points: 'pointManagement',
        }
    }
}
export default en;
